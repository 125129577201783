import Vue from 'vue';
import { mapGetters } from 'vuex';

Vue.mixin({
  computed: {
    ...mapGetters(['isMobile', 'isOffline', 'currentHost']),
    isDev () {
      return false;
    },
    isSulu () {
      return process.env.type === 'sulu';
    },
    suluUrl () {
      return process.env.suluURL;
    },
  },
  methods: {
    formatLocaleItems (items, localeKey, additionalKey) {
      if (!items || !items.length) return [];

      const additionalValue = additionalKey
        ? `.${additionalKey}`
        : '';

      return items.map(item => typeof item === 'object'
        ? { title: this.$t(`${localeKey}${additionalValue}.${item.value}`), ...item }
        : { title: this.$t(`${localeKey}${additionalValue}.${item}`) }
      );
    },
    formatLocaleObjects (items, localeKey, additionalKey) {
      if (!items || !items.length) return [];

      const additionalValue = additionalKey
        ? `.${additionalKey}`
        : '';

      return items.map(item => typeof item === 'object'
        ? { ...this.$t(`${localeKey}${additionalValue}.${item.value}`), ...item }
        : { ...this.$t(`${localeKey}${additionalValue}.${item}`) }
      );
    },
    getLocaleItem (key) {
      if (!key || !this.localeKey) return '';
      return this.$t(`${this.localeKey}.${key}`);
    },
    hideOverflow () {
      if (typeof document === 'undefined') return;
      document.body.classList.add('lock');
    },
    showOverflow () {
      if (typeof document === 'undefined') return;
      document.body.classList.remove('lock');
    }
  },
});

const COUNTRIES = {
  RUB: 'ru',
  USD: 'en',
};

// eslint-disable-next-line
Vue.prototype.$localizeCurrency = (value, currency) => `${Vue.prototype.$localize.n(value, 'currency', COUNTRIES[currency])}`;
