
export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  computed: {
    message () {
      let key = '';

      switch (this.error.statusCode) {
      case 403:
        key = 'pageRestricted';
        break;
      case 404:
        key = 'pageNotFound';
        break;
      default:
        key = 'unknown';
      }

      return this.$t(`errors.${key}`);
    },
  },
};
