import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1280e05d = () => interopDefault(import('../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _d772849a = () => interopDefault(import('../src/pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _ae3bfc72 = () => interopDefault(import('../src/pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _685890e2 = () => interopDefault(import('../src/pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _5f4b889e = () => interopDefault(import('../src/pages/products/cargo.vue' /* webpackChunkName: "pages/products/cargo" */))
const _e05e6a68 = () => interopDefault(import('../src/pages/support/cargo.vue' /* webpackChunkName: "pages/support/cargo" */))
const _3238bb22 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9e62fd58 = () => interopDefault(import('../src/pages/products/_.vue' /* webpackChunkName: "pages/products/_" */))
const _11d3bfa2 = () => interopDefault(import('../src/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _1280e05d,
    meta: {"name":"about"},
    name: "about"
  }, {
    path: "/contacts",
    component: _d772849a,
    meta: {"name":"contacts-page"},
    name: "contacts"
  }, {
    path: "/products",
    component: _ae3bfc72,
    meta: {"name":"products"},
    name: "products"
  }, {
    path: "/support",
    component: _685890e2,
    meta: {"name":"support"},
    name: "support"
  }, {
    path: "/products/cargo",
    component: _5f4b889e,
    meta: {"name":"products-page"},
    name: "products-cargo"
  }, {
    path: "/support/cargo",
    component: _e05e6a68,
    meta: {"name":"support-cargo"},
    name: "support-cargo"
  }, {
    path: "/",
    component: _3238bb22,
    meta: {"name":"index-page","layout":"index"},
    name: "index"
  }, {
    path: "/products/*",
    component: _9e62fd58,
    meta: {"name":"products-page"},
    name: "products-all"
  }, {
    path: "/*",
    component: _11d3bfa2,
    meta: {"name":"headless-page","layout":"index"},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
