import Vue from 'vue';
import Sender from 'razlet-sulu-sdk/lib/api/sender';
// import {sendError} from '~/utils/error';

const state = () => ({
  // eslint-disable-next-line
  ua: /iPhone|iPad|iPod|Android|Windows Phone/.test(typeof window !== 'undefined' ? window.navigator.userAgent : ''),
  mobile: false,
  offline: false,
  host: null,
});

const mutations = {
  setMobile (state, value) {
    state.mobile = value;
  },
  setOffline: (state, value) => {
    state.offline = value;
  },
};

const getters = {
  isMobile (state) {
    return state.mobile;
  },
  isOffline (state) {
    return state.offline;
  },
};

const actions = {
  async nuxtServerInit ({ dispatch }, { app, req, isDev }) {
    const currentLang = app.$cookiz.get('defaultLanguage') || 'ru';
    Vue.prototype.$locale = currentLang;
    await app.i18n.setLocale(currentLang);
    await dispatch('sulu/initSuluSender', { req, isDev, requests: ['getMenu'] });
  },
  // eslint-disable-next-line no-empty-pattern
  async sendFeedback ({ state }, data) {
    const sender = new Sender({ baseUrl: `${state.sulu.host}/${Vue.prototype.$locale}/api` });
    const response = await sender.post('/feedback/send', data);

    if (response.status !== 200) {
      return Promise.reject(response.data);
    }

    return response;
  },
};

export default {
  strict: false,
  state,
  getters,
  mutations,
  actions,
};
